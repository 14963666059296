<template>
  <div>
    <div class="total">
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="price">+{{ info.incomeAmount }}</div>
          <div class="title">收入到账合计</div>
        </el-col>
        <el-col :span="6">
          <div class="price">+{{ info.incomeUnSettle }}</div>
          <div class="title">收入未分账合计</div>
        </el-col>
        <el-col :span="6">
          <div class="price">-{{ info.expendUnSettle }}</div>
          <div class="title">支出未分账合计</div>
        </el-col>
        <el-col :span="6">
          <div class="price">{{ info.abnormalCount }}</div>
          <div class="title">异常账单</div>
        </el-col>
      </el-row>
    </div>

    <div class="merchantCenterList MERCH">
      <!-- 供应商中心 -->
      <div class="merch-card">
        <!-- 搜索列 -->
        <div class="merch-search">
          <el-form ref="searchParams" :model="searchParams" :inline="true">
            <div style="display: flex">
              <el-form-item style="width: 25%">
                <el-date-picker
                  v-model="dateTimes"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="账单开始日期"
                  end-placeholder="账单结束日期"
                  unlink-panels
                  value-format="yyyy-MM-dd"
                />
              </el-form-item>
              <el-form-item style="width: 25%">
                <el-input
                  maxlength="32"
                  v-model="searchParams.sn"
                  placeholder="请输入账单编号"
                  clearable
                  @keyup.enter.native="handleCurrentChange"
                />
              </el-form-item>
              <el-form-item style="width: 25%">
                <el-input
                  maxlength="32"
                  v-model="searchParams.orderGroupId"
                  placeholder="请输入平台批次号"
                  clearable
                  @keyup.enter.native="handleCurrentChange"
                />
              </el-form-item>
              <el-form-item style="width: 25%">
                <el-input
                  maxlength="32"
                  v-model="searchParams.paySerial"
                  placeholder="请输入支付流水号"
                  clearable
                  @keyup.enter.native="handleCurrentChange"
                />
              </el-form-item>
            </div>

            <div style="display: flex">
              <el-form-item prop="complainant" style="flex: 1">
                <el-select
                  style="width: 100%"
                  v-model="searchParams.statementResult"
                  clearable
                  placeholder="请选择支付对账结果"
                >
                  <el-option label="正常" value="1"></el-option>
                  <el-option label="金额不一致" value="2"></el-option>
                  <el-option label="多账" value="3"></el-option>
                  <el-option label="少账" value="4"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="complaint" style="flex: 1">
                <el-input
                  maxlength="32"
                  v-model="searchParams.billTarget"
                  placeholder="账单单位"
                  clearable
                  @keyup.enter.native="handleCurrentChange"
                />
              </el-form-item>
              <el-form-item prop="complainant" style="flex: 1">
                <el-select
                  style="width: 100%"
                  v-model="searchParams.status"
                  clearable
                  placeholder="请选择分账状态"
                >
                  <el-option label="未结算" value="0"></el-option>
                  <el-option label="已结算" value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="complaint" style="flex: 1">
                <el-select
                  style="width: 100%"
                  v-model="searchParams.roleType"
                  clearable
                  placeholder="请选择交易角色"
                >
                  <el-option label="供应商" value="0"></el-option>
                  <el-option label="业务员" value="1"></el-option>
                  <el-option label="平台" value="2"></el-option>
                  <el-option label="销售商" value="3"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div style="display: flex">
              <el-form-item prop="supplier" style="flex: 1">
                <el-input
                  autocomplete="off"
                  maxlength="32"
                  v-model="searchParams.accountName"
                  placeholder="请输入交易账户名"
                  clearable
                  @keyup.enter.native="handleCurrentChange"
                />
              </el-form-item>
              <el-form-item prop="complaint" style="flex: 1">
                <el-input
                  autocomplete="off"
                  maxlength="32"
                  v-model="searchParams.accountNo"
                  placeholder="请输入交易账户号"
                  clearable
                  @keyup.enter.native="handleCurrentChange"
                />
              </el-form-item>
              <el-form-item prop="complaint" style="flex: 1">
                <el-select
                  style="width: 100%"
                  v-model="searchParams.serviceType"
                  clearable
                  placeholder="请选择业务类型"
                >
                  <el-option label="商品货款" value="0"></el-option>
                  <el-option label="平台佣金" value="1"></el-option>
                  <el-option label="业务员佣金" value="2"></el-option>
                  <el-option label="商家佣金" value="3"></el-option>
                  <el-option label="运费" value="4"></el-option>
                  <el-option label="平台手续费" value="5"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="complaint" style="flex: 1">
                <el-button type="primary" @click="handleCurrentChange"
                  >查 询</el-button
                >
                <el-button @click="resetting">重 置</el-button>
                <el-button type="primary" @click="onExport">导 出</el-button>
              </el-form-item>
            </div>
          </el-form>
        </div>

        <!-- 列表 -->
        <div class="merch-table scorr-roll">
          <div>
            <el-table :data="searchListData" border style="width: 100%">
              <el-table-column
                prop="id"
                label="账单编号"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="orderGroupId"
                label="平台交易批次号"
                align="center"
                width="200"
              ></el-table-column>
              <el-table-column
                prop="splitOrderNo"
                label="分账订单号"
                align="center"
                width="200"
              ></el-table-column>
              <el-table-column
                prop="paySerial"
                label="京东交易流水号"
                align="center"
                width="210"
              ></el-table-column>
              <el-table-column label="订单类型" align="center" width="100">
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.orderType == 0 ? "销售订单" : "退款订单" }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="交易角色" align="center" width="100">
                <template slot-scope="scope">
                  <div>{{ getRole(scope.row.roleType) }}</div>
                </template>
              </el-table-column>
              <el-table-column
                prop="settlementObject"
                label="账单单位"
                align="center"
                width="150"
              ></el-table-column>
              <el-table-column label="支付渠道" align="center" width="100">
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.paymentChannel == 0 ? "京东支付" : "-" }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="accountName"
                label="交易账户名"
                align="center"
                width="200"
              ></el-table-column>
              <el-table-column
                prop="accountNo"
                label="交易账户号"
                align="center"
                width="150"
              ></el-table-column>
              <el-table-column label="账单类型" align="center" width="100">
                <template slot-scope="scope">
                  <div>{{ scope.row.inOutType == 1 ? "收入" : "支出" }}</div>
                </template>
              </el-table-column>
              <el-table-column label="结算状态" align="center" width="100">
                <template slot-scope="scope">
                  <div>{{ scope.row.status == 0 ? "未结算" : "已结算" }}</div>
                </template>
              </el-table-column>
              <el-table-column label="业务类型" align="center" width="100">
                <template slot-scope="scope">
                  <div>{{ getServiceType(scope.row.serviceType) }}</div>
                </template>
              </el-table-column>
              <el-table-column
                prop="amount"
                label="平台账单金额"
                align="center"
                width="200"
              ></el-table-column>
              <el-table-column
                prop="jdAmount"
                label="支付平台金额"
                align="center"
                width="150"
              ></el-table-column>
              <el-table-column
                prop="serviceFee"
                label="支付手续费"
                align="center"
                width="150"
              ></el-table-column>
              <el-table-column
                prop="serviceFeeRatio"
                label="手续费比例"
                align="center"
                width="100"
              ></el-table-column>
              <el-table-column label="支付对账结果" align="center" width="150">
                <template slot-scope="scope">
                  <div>{{ getResult(scope.row.statementResult) }}</div>
                </template>
              </el-table-column>
              <el-table-column
                prop="tradeTime"
                label="交易时间"
                align="center"
                width="200"
              ></el-table-column>
              <el-table-column
                prop="settlementTime"
                label="结算时间"
                align="center"
                width="200"
              ></el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 分页 -->
        <div class="merch-page">
          <el-pagination
            background
            @current-change="searchBusinessList"
            layout="total, prev, pager, next, jumper"
            :total="total"
            :page-size="10"
            :current-page="current_page"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import { downLoadZip } from "@/utils/zipdownload";
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const billList = createNamespacedHelpers("billList");
export default {
  data() {
    return {
      BillingResults: "",

      info: {
        abnormalCount: "0",
        expendUnSettle: "0.00",
        incomeAmount: "0.00",
        incomeUnSettle: "0.00",
      },
      total: 0,
      current_page: null,
      dateTimes: "",
      searchParams: {
        page: 1,
        perPage: 10,
        startTime: "",
        endTime: "",
        sn: "", //账单编号
        orderGroupId: "", //平台批次号
        paySerial: "", //支付流水号
        statementResult: "", //支付对账结果 1-正常 2-金额不一致 3-多账 4-少账
        billTarget: "",
        status: "", //分账状态 0-未结算，1-已结算
        roleType: "", //交易角色(0:供应商;1:业务员;2:平台;3:销售商)
        accountName: "", //交易账户名
        accountNo: "", //交易账户号
        serviceType: "", //0:商品货款;1:平台佣金;2;业务员佣金;3:商家佣金4:运费
      },
      searchListData: [],
    };
  },
  computed: {
    ...commonIndex.mapState(["mainHright"]),
    ...billList.mapState(["page"]),
  },
  created() {
    this.getSearchList();
    this.getData();
  },
  methods: {
    ...billList.mapActions(["statementWater", "statementWaterExport"]),
    async onExport() {
      this.searchParams.startTime = "";
      this.searchParams.endTime = "";
      if (this.dateTimes) {
        this.searchParams.startTime = this.dateTimes[0];
        this.searchParams.endTime = this.dateTimes[1];
      }
      if (this.searchParams.hasOwnProperty("opt")) {
        delete this.searchParams.opt;
      }
      delete this.searchParams.page;
      delete this.searchParams.perPage;
      // await this.statementWaterExport(this.searchParams)
      downLoadZip(
        `/orderStatement/statementWaterExport`,
        "xlsx",
        "支付对账(订单)",
        this.searchParams,
        "php",
        "get"
      );
    },
    async getData() {
      this.searchParams.startTime = "";
      this.searchParams.endTime = "";
      if (this.dateTimes) {
        this.searchParams.startTime = this.dateTimes[0];
        this.searchParams.endTime = this.dateTimes[1];
      }
      this.searchParams.opt = "billStatistics";
      let { data } = await this.statementWater(this.searchParams);
      this.info = data;
    },
    async getSearchList() {
      this.searchParams.startTime = "";
      this.searchParams.endTime = "";
      if (this.dateTimes) {
        this.searchParams.startTime = this.dateTimes[0];
        this.searchParams.endTime = this.dateTimes[1];
      }
      if (this.searchParams.hasOwnProperty("opt")) {
        delete this.searchParams.opt;
      }
      let { data } = await this.statementWater(this.searchParams);
      this.searchListData = data.data;
      this.current_page = data.currentPage;
      this.total = data.total;
    },
    //当前页
    searchBusinessList(e) {
      if (e != undefined) {
        this.searchParams.page = e;
      }
      this.getSearchList();
    },
    //搜索
    handleCurrentChange() {
      this.searchParams.page = 1;
      this.getSearchList();
      this.getData();
    },
    resetting() {
      this.dateTimes = "";
      this.searchParams.startTime = "";
      (this.searchParams.endTime = ""),
        (this.searchParams.sn = ""), //账单编号
        (this.searchParams.orderGroupId = ""), //平台批次号
        (this.searchParams.paySerial = ""), //支付流水号
        (this.searchParams.statementResult = ""), //支付对账结果 1-正常 2-金额不一致 3-多账 4-少账
        (this.searchParams.billTarget = ""),
        (this.searchParams.status = ""), //分账状态 0-未结算，1-已结算
        (this.searchParams.roleType = ""), //交易角色(0:供应商;1:业务员;2:平台;3:销售商)
        (this.searchParams.accountName = ""), //交易账户名
        (this.searchParams.accountNo = ""), //交易账户号
        (this.searchParams.serviceType = "");
      this.handleCurrentChange();
    },
    getRole(val) {
      let arr = ["供应商", "业务员", "平台", "销售商"];
      return arr[val];
    },
    getServiceType(val) {
      let arr = [
        "商品货款",
        "平台佣金",
        "业务员佣金",
        "商家佣金",
        "运费",
        "平台手续费",
      ];
      return arr[val];
    },
    getResult(val) {
      let arr = ["", "正常", "金额不一致", "多账", "少账"];
      return arr[val];
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-form {
  display: flex;
  flex-direction: column;
}

::v-deep .el-form-item__content {
  width: 90%;
}

::v-deep .el-form--inline .el-form-item {
  text-align: center;
}

::v-deep .el-tabs__item.is-active {
  color: #06b7ae;
}

::v-deep .el-tabs__item:hover {
  color: #06b7ae;
}

::v-deep .details {
  color: #06b7ae;
  padding: 10px;
}

::v-deep .el-tabs__active-bar {
  background-color: #06b7ae;
}

::v-deep .el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}

::v-deep .el-input__inner {
  border-radius: 4px !important;
}

::v-deep .el-range-separator {
  width: 20% !important;
}
</style>
<style lang="scss" scoped>
.MERCH {
  width: 100%;
  height: 100%;

  .merch-card {
    width: 100%;
    max-height: 100%;
    padding: 20px;
    padding-top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;

    .merch-tag {
      width: 100%;
      height: 40px;
    }

    // 搜索行样式
    .merch-search {
      margin-top: 1%;
      width: 100%;

      .search-box {
        display: flex;
        flex-wrap: wrap;
      }

      .sch-1 {
        margin-top: 10px;
        width: 18%;
        min-width: 200px;
        display: flex;

        .sch-title {
          width: 40%;
          min-width: 80px;
          height: 100%;
          font-size: 13px;
          background-color: #f5f7fa;
          color: #909399;
          border: 1px solid #dcdfe6;
          border-right: none;
          border-radius: 4px 0px 0px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 0px 0px 5px !important;
        }
      }

      .sch-2 {
        margin-right: 2%;
      }
    }

    .merch-table {
      //   margin-top: 1%;
      width: 100%;
      height: 77%;
    }

    .merch-page {
      margin-top: 10px;
      width: 100%;
      display: flex;
      align-content: center;
      justify-content: right;
    }
  }
}

.el-select {
  width: 325px;
}

.el-button--default {
  background-color: rgba($color: #2e3238, $alpha: 0.05);
  color: #383b46;
  border: none;
}

.total {
  margin: 10px;

  .el-row {
    background-color: #ffffff;
    padding: 30px 0;
    border-radius: 4px;
    font-weight: bold;
  }

  .el-col {
    text-align: center;

    .title {
      margin-top: 20px;
    }
  }
}
</style>
    